<template>
  <div class="help grey lighten-2 d-flex flex-column" :class="{'mobile': $vuetify.breakpoint.smAndDown}">

    <main-nav v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>


    <div class="fill-height d-flex align-center justify-center">

      <div id="myLandbot">

      </div>



    </div>

  </div>
</template>


<script>
//   var myLandbot = new LandbotFrameWidget({
//     container: '#myLandbot',
//     index: 'https://landbot.io/u/H-397125-XEHSJMQ8YWOXOCGV/index.html',
//   });
const mobileNav =() => import("@/components/shared/mobile-nav.vue");
const mainNav = () => import("@/components/shared/nav.vue");
const videoCards = () => import("@/components/shared/video-cards.vue");
import { db } from '@/firebase';
import '@/plugins/UmiAccessPoint.js';
import '@/plugins/landbot-widget-1.0.0.js';

/*global LandbotFullpage, a*/
/*eslint no-undef: "error"*/

export default {
  name: "help",
  metaInfo: {
    title: 'Youtorial: Help, sometimes we need it...'
  },
  components:{
    mainNav,videoCards,mobileNav,
  },

  data(){
    return{
      videos: [],
      isLoaded: false,
      loading:true,
      userId:0,
    }
  },
  methods:{
    loadWidget(){
      let myLandbot = new LandbotFullpage({

        index: "https://landbot.io/u/H-397125-XEHSJMQ8YWOXOCGV/index.html"
      });
      // console.log('myLandbot', LandbotFullpage);
      // console.log('myLandbot', myLandbot);

    },
  },
  mounted(){
    let vThis = this;
    if(this.$vuetify.breakpoint.mdAndUp){
      this.$store.dispatch('toggleDrawer', true);
    }

    setTimeout(function(){
      vThis.loadWidget();
    }, 1000)

    // let widget = document.createElement('script');
    // widget.setAttribute('src',"https://static.landbot.io/umicore/UmiAccessPoint.js");
    // widget.async = true;
    // document.head.appendChild(widget);

    // let landbot = document.createElement('script');
    // landbot.async = true;
    // landbot.setAttribute('src',"https://static.landbot.io/landbot-widget/landbot-widget-1.0.0.js");
    //
    // document.head.appendChild(landbot);
    //
    //
    // landbot.onload = function(){
    //     vThis.loadWidget();
    // }



  }

};
</script>


<style lang="scss">
.help{
  position: relative;
  height: 100vh;
  padding-top: 70px;
  padding-left: 280px;
//   background: rgba(75,192,200,1);
// background: -moz-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: -webkit-gradient(left top, right top, color-stop(0%, rgba(75,192,200,1)), color-stop(50%, rgba(199,121,208,0.69)), color-stop(100%, rgba(254,171,94,0.37)));
// background: -webkit-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: -o-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: -ms-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: linear-gradient(to right, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4bc0c8', endColorstr='#feab5e', GradientType=1 );
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
    }
    #myLandbot{
      margin-left: 0;
      height: 100%;
      .LandbotFullpage-container{
        left:0!important;
        width: 100%!important;
        .LandbotFullpage{
          width: 100%!important;
          left: 0!important;
        }
      }
    }
  }
  #myLandbot{
    height: 100%;
    width: 100%;
    margin-left: 280px;
  }

}
.LandbotFullpage-container{
  width: calc(100% - 280px)!important;
  left: 280px!important;
  z-index: 1!important;
  .LandbotFullpage{
    width: calc(100% - 280px)!important;
    left: 280px!important;
    z-index: 1!important;
  }
}
</style>
